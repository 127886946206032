import axios from 'axios'
import $store from '@/store/store'

const cconfig = {
  // eslint-disable-next-line no-undef
  url: '/apisf', //proxy (vue.config)
  urltkn: '/tkn_apisf', //proxy (vue.config)
  path: '/services/data/v46.0'
}

const callsfrest = async (aconfig, responseType) => {
  if ($store.getters.user && $store.getters.user.sf && $store.getters.user.sf.token) {
    return call(aconfig, $store.getters.user.sf.token, responseType).then(response => {
      return response
    })
  } else {
    //gerar token no sf, via proxy para evitar cors error (vide vue.config)
    return axios({
        url: cconfig.urltkn + '/services/oauth2/token',
        method: 'post',
        params: {
          'grant_type': 'password',
          'client_id': '3MVG9LBJLApeX_PCTB7a1ByuA8BIni5cV.voBrQjXhob7itN4fqsJVBkjvhmKt9AP6ATEAfsiaQfwwbak4e4t',
          'client_secret': '550292D546474DFF5D388277570B4847B1F2178F6D22DE1139BB543F85328166',
          'username': 'prd_integration@firecodeit.net',
          'password': '0MONEY$moneykkFTozdMmta9jt990R1dkEbNo',
        },
      })
      .then(response => call(aconfig, response.data.access_token)).catch((error) => {})
  }
};

const call = (aconfig, token, responseType) => {
  const url = `${cconfig.url}${cconfig.path}${aconfig.url}`;

  const requestSettings = {
    method: aconfig.method,
    url: url,
    params: aconfig.params,
    data: aconfig.data,
    headers: {
      Authorization: `Bearer ${token}`,
      'Access-Control-Max-Age': 600,
      'Cache-Control': 'max-age=600',
    },
  };

  if (responseType) {
    requestSettings.responseType = responseType;
  }

  return axios(requestSettings)
    .then(response => {
      if (!response.data) response.data = {}
      if (typeof response.data !== 'string') {
        response.data.token = token;
      }
      return response.data
    }).catch((error) => {})
}

export {
  callsfrest
};
